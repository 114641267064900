import React from 'react';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import clsx from 'clsx';

export interface ActiveLinkProps extends LinkProps {
  children: React.ReactElement | string;
  activeClassname: string;
  className?: string;
  onClick?: () => void;
  qaAttribute?: string;
}

function ActiveLink(props: ActiveLinkProps) {
  const { children, href, className, activeClassname, onClick, qaAttribute, ...otherLinkProps } = props;

  const { pathname, asPath } = useRouter();

  const isBlogArticlePage = href === '/blog' && asPath.includes(href);

  const rootClassName = clsx(className, {
    [activeClassname]: pathname === href || isBlogArticlePage,
  });

  return (
    <Link href={href} onClick={onClick} className={rootClassName} data-qa={qaAttribute} {...otherLinkProps}>
      {children}
    </Link>
  );
}

export default ActiveLink;
