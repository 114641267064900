import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import { links } from '@/config/links';

import CmmxTextLogo from '@/icons/cmmx-text-logo.svg';
import ActiveLink from '@/components/active-link';
import OptimizedImage from '@/components/optimized-image';
import TopBanner from '@/components/top-banner';

function Header(): JSX.Element {
  const { formatMessage } = useIntl();
  const [isMobileMenuHidden, setIsMobileMenuHidden] = useState(true);
  const headerRef = useRef<HTMLHeadElement>(null);

  useEffect(() => {
    const handleHeaderScroll = () => {
      if (window.scrollY > 0 && headerRef.current) {
        headerRef.current?.classList.add('shadow-md');
      } else {
        headerRef.current?.classList.remove('shadow-md');
      }
    };

    window.addEventListener('scroll', handleHeaderScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleHeaderScroll);
  }, []);

  const openMobileMenu = () => {
    setIsMobileMenuHidden(false);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuHidden(true);
  };

  const handleLinkClick = () => closeMobileMenu();

  return (
    <header className="sticky top-0 left-0 w-full bg-white z-40 mb-8 md:mb-10" ref={headerRef}>
      <TopBanner />
      <div className="mx-auto max-w-screen-lg px-4 py-4 lg:py-0 flex items-center justify-between">
        <Link href="/" className="flex items-center space-x-2" data-qa="logo-header-desktop">
          <OptimizedImage src="/images/clean-my-mac@2x.png" alt="CleanMyMac X" width="32" height="25" />
          <CmmxTextLogo />
        </Link>
        <nav className="hidden lg:block">
          <ul className="flex space-x-8">
            <li>
              <ActiveLink
                href="/"
                className="link"
                activeClassname="link--current"
                qaAttribute="main-page-header-link-desktop"
              >
                {formatMessage({ defaultMessage: 'main', description: 'header menu item' })}
              </ActiveLink>
            </li>
            <li>
              <ActiveLink
                href="/blog"
                className="link"
                activeClassname="link--current"
                qaAttribute="blog-page-header-link-desktop"
              >
                {formatMessage({ defaultMessage: 'blog', description: 'header menu item' })}
              </ActiveLink>
            </li>
            <li>
              <ActiveLink
                href="/support/contact"
                className="link"
                activeClassname="link--current"
                qaAttribute="support-page-header-link-desktop"
              >
                {formatMessage({ defaultMessage: 'support', description: 'header menu item' })}
              </ActiveLink>
            </li>
          </ul>
        </nav>
        <nav className="hidden lg:block">
          <ul className="flex space-x-4">
            <li>
              <a
                className="btn btn-green h-10"
                target="_blank"
                href={links.cmmStore}
                rel="noreferrer"
                data-qa="buy-now-header"
              >
                {formatMessage({ defaultMessage: 'Buy now' })}
              </a>
            </li>
            <li>
              <a
                href={links.cmmDownload}
                className="btn btn-outline btn-outline-gray h-10"
                target="_blank"
                rel="noreferrer"
                data-qa="free-download-header"
              >
                {formatMessage({ defaultMessage: 'Free download' })}
              </a>
            </li>
          </ul>
        </nav>
        <button
          className="lg:hidden text-sm"
          type="button"
          onClick={openMobileMenu}
          data-qa="toggle-menu-heder-desktop"
        >
          {formatMessage({ defaultMessage: 'menu', description: 'menu toggle button' })}
        </button>
      </div>

      <div className="px-4 py-4 bg-white z-50 fixed top-0 left-0 w-full h-full" hidden={isMobileMenuHidden}>
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between mb-4">
            <Link
              href="/"
              className="flex items-center space-x-2"
              onClick={handleLinkClick}
              data-qa="main-page-header-link-mobile"
            >
              <OptimizedImage src="/images/clean-my-mac@2x.png" alt="CleanMyMac X" width="32" height="25" />
              <CmmxTextLogo />
            </Link>
            <button
              className="text-sm font-bold relative before:absolute before:-bottom-4 before:bg-macpaw-black before:h-[2px] before:block before:w-full"
              type="button"
              onClick={closeMobileMenu}
              data-qa="toggle-menu-heder-mobile"
            >
              {formatMessage({ defaultMessage: 'menu', description: 'menu toggle button' })}
            </button>
          </div>
          <nav className="flex flex-1 items-center">
            <ul className="space-y-5">
              <li>
                <ActiveLink
                  onClick={handleLinkClick}
                  href="/"
                  className="link text-4xl"
                  activeClassname="link--current"
                  qaAttribute="main-page-header-link-mobile"
                >
                  {formatMessage({ defaultMessage: 'main', description: 'header menu item' })}
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  onClick={handleLinkClick}
                  href="/blog"
                  className="link text-4xl"
                  activeClassname="link--current"
                  qaAttribute="blog-page-header-link-mobile"
                >
                  {formatMessage({ defaultMessage: 'blog', description: 'header menu item' })}
                </ActiveLink>
              </li>
              <li>
                <ActiveLink
                  onClick={handleLinkClick}
                  href="/support/contact"
                  className="link text-4xl"
                  activeClassname="link--current"
                  qaAttribute="support-page-header-link-mobile"
                >
                  {formatMessage({ defaultMessage: 'support', description: 'header menu item' })}
                </ActiveLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
