/* @Hooks */
import { useIntl } from 'react-intl';

import { SocialLink } from '@/types/common';

/* @Icons */
import Facebook from '@/icons/facebook.svg';
import Instagram from '@/icons/instagram.svg';
import Twitter from '@/icons/twitter.svg';
import Youtube from '@/icons/youtube.svg';

interface FooterProps {
  socialLinks: SocialLink[];
}

const socialIconByName: { [iconName: string]: JSX.Element } = {
  facebook: <Facebook />,
  instagram: <Instagram />,
  twitter: <Twitter />,
  youtube: <Youtube />,
};

function Footer(props: FooterProps): JSX.Element {
  const { socialLinks } = props;
  const { formatMessage } = useIntl();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="mx-auto max-w-screen-lg px-4 flex flex-col items-center md:items-start justify-center md:flex-row py-10 md:py-4 md:space-x-10 text-center md:text-left">
        <p className="mb-8 md:mb-0 opacity-40 w-full md:w-3/12 lg:w-4/12">
          {formatMessage({ defaultMessage: 'Copyright © {currentYear} MacPaw Way Ltd.' }, { currentYear })}
        </p>
        <nav className="mb-8 md:mb-0 w-full md:w-6/12">
          <ul className="md:columns-2 md:gap-10 md:space-y-1 space-y-4 inline-block">
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/cookies"
                rel="noreferrer"
                className="border-transparent border-b-2 hover:border-current transition-colors"
                data-qa="cookies-footer"
              >
                {formatMessage({ defaultMessage: 'Cookie policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/policy"
                rel="noreferrer"
                className="border-transparent border-b-2 hover:border-current transition-colors"
                data-qa="policy-footer"
              >
                {formatMessage({ defaultMessage: 'Privacy policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/refund-policy"
                rel="noreferrer"
                className="border-transparent border-b-2 hover:border-current transition-colors"
                data-qa="refund-footer"
              >
                {formatMessage({ defaultMessage: 'Refund policy' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/eula"
                rel="noreferrer"
                className="border-transparent border-b-2 hover:border-current transition-colors"
                data-qa="eula-footer"
              >
                {formatMessage({ defaultMessage: 'License agreement' })}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://macpaw.com/ja/support/cleanmymac/knowledgebase/uninstall-cleanmymac-x"
                rel="noreferrer"
                className="border-transparent border-b-2 hover:border-current transition-colors"
                data-qa="uninstall-footer"
              >
                {formatMessage({ defaultMessage: 'Uninstall' })}
              </a>
            </li>
          </ul>
        </nav>
        <nav className="w-full md:w-3/12 lg:w-2/12">
          <p className="font-bold mb-4 md:mb-1">{formatMessage({ defaultMessage: 'follow us' })}</p>
          <ul className="flex space-x-4 justify-center md:justify-start">
            {socialLinks.map(
              ({ name, href }) =>
                href && (
                  <li key={name}>
                    <a
                      href={href}
                      aria-label={name}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-center w-8 h-8 rounded bg-black bg-opacity-5 hover:bg-opacity-10 active:bg-opacity-20 transition-colors"
                      data-qa={`${name}-footer`}
                    >
                      {socialIconByName[name]}
                    </a>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
