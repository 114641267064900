import { useState, useEffect } from 'react';

import { links } from '@/config/links';

import OptimizedImage from '@/components/optimized-image';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const OFFER_STARTS = new Date('2023/12/19 10:00:00 GMT+02:00').getTime();
const OFFER_ENDS = new Date('2023/12/24 10:00:00 GMT+02:00').getTime();
const OFFERS_ANALYTICS = 'Christmas Sale 2023';

function handleAnalytics(type: string) {
  window.dataLayer.push({
    event: `promotion${type}`,
    ecommerce: {
      [`promo${type}`]: {
        promotions: [
          {
            name: OFFERS_ANALYTICS,
          },
        ],
      },
    },
  });
}

function TopBanner(): JSX.Element {
  const [isOfferActive, setIsOfferActive] = useState(false);

  useEffect(() => {
    const currentDate = Date.now();
    if (currentDate > OFFER_STARTS && currentDate < OFFER_ENDS) {
      setIsOfferActive(true);
      handleAnalytics('View');
    } else {
      setIsOfferActive(false);
    }
  }, []);

  if (!isOfferActive) {
    return <></>;
  }

  return (
    <div className="bg-[#856ED7] md:h-[104px]">
      <div className="relative z-10 max-w-[1120px] h-full m-auto pb-6 px-4 md:py-5 md:px-4 flex flex-col items-center justify-center md:flex-row md:justify-between">
        <button
          type="button"
          className="hidden md:block opacity-40 hover:opacity-80 mr-4"
          onClick={() => setIsOfferActive(false)}
        >
          <svg fill="none" height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
            <g opacity=".4" stroke="#fff" strokeWidth="2">
              <path d="m3 3 16 16" />
              <path d="m19 3-16 16" />
            </g>
          </svg>
        </button>
        <div className="md:hidden mb-4">
          <OptimizedImage src="images/top-banner/img_mobile.png" width="360" height="180" />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <OptimizedImage src="images/top-banner/title1.png" alt="あなたはホリデーを満喫。" width="235" height="25" />
          <div className="mb-4 md:mb-0">
            <OptimizedImage src="images/top-banner/title2.png" alt="私達がMacを整備" width="188" height="25" />
          </div>
        </div>
        <div className="hidden md:block mr-7 lg:mr-0">
          <OptimizedImage src="images/top-banner/img_desktop.png" width="320" height="104" />
        </div>
        <a
          className="min-w-[179px] md:min-w-[150px] lg:min-w-[179px] py-2 text-lg text-center mb-4 font-bold rounded bg-[#FFDD2C] hover:bg-[#FFE456] focus:bg-[#E6C728] md:mb-0 border-black border drop-shadow-[2px_2px_black]"
          target="_blank"
          href={links.macpawStore}
          onClick={() => handleAnalytics('Click')}
          rel="noreferrer"
        >
          30%割引で取得
        </a>
        <button
          type="button"
          className="md:hidden min-w-[117px] py-2 text-lg text-center font-bold rounded bg-[#856ED7] border-black border drop-shadow-[2px_2px_black]"
          onClick={() => setIsOfferActive(false)}
        >
          を閉じる
        </button>
      </div>
    </div>
  );
}

export default TopBanner;
