import { ReactNode } from 'react';

import Header from '@/components/header';
import Footer from '@/components/footer';
import { socialLinks } from '@/config/social-links';

interface LayoutProps {
  children: ReactNode;
}

function Layout(props: LayoutProps): JSX.Element {
  return (
    <>
      <Header />
      <main className="flex-grow flex flex-col">{props.children}</main>
      <Footer socialLinks={socialLinks} />
    </>
  );
}

export default Layout;
